<template>
    <div>
        <v-form id="form_edit_parking_permit" ref="form" v-model="valid" lazy-validation>
            <v-layout row wrap align-center justify-center fill-height>
                <v-flex xs12 sm12 md10 lg10 xl8>
                    <v-card id="form_card">
                        <v-img
                        :src="imgParking"
                        height="300px"
                        ></v-img>
                        <v-card-title>
                            <span class="subtitle-1 application--card--title text-center">
                            <v-icon left>mdi-file-document-edit-outline</v-icon>
                            Parkirna dovolilnica št. #{{ permitId }}</span>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="toggleCardContent('card1')">
                                <v-icon>{{ card1 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-divider v-show="card1"></v-divider>
                        <v-card-text v-show="card1">
                            <p style="" class="body-2 font-weight-regular card--text"><v-icon class="p--icon" medium color="secondary" left>mdi-information</v-icon>Polja označena z * so vezana na podatke o uporaniku.</p>
                            
                            <form-validation-messages :messages="apiValidationMessages"></form-validation-messages>
                            <form-submit-messages :messages="apiMessages" :errorMessages="apiErrorMessages"></form-submit-messages>

                            

                            <!-- imetnik -->
                            <v-text-field
                                v-model="name"
                                label="Ime in priimek*"
                                required
                                :rules="name_rules"
                                filled
                            ></v-text-field>

                            <!-- ulica -->
                            <v-text-field
                                v-model="address"
                                label="Naslov (prebivališče)*"
                                required
                                :rules="address_rules"
                                filled
                            ></v-text-field>

                            <!-- kraj -->
                            <v-text-field
                                v-model="town"
                                label="Kraj*"
                                required
                                :rules="town_rules"
                                filled
                            ></v-text-field>

                            <!-- poštna številka -->
                            <v-text-field
                                v-model="postal_code"
                                label="Poštna številka*"
                                required
                                :rules="postal_code_rules"
                                type="number"
                                filled
                            ></v-text-field>

                            <!-- izbira prebivališča -->
                            <v-select
                            :items="residenceTypes"
                            label="Tip prebivališča*"
                            item-text="name"
                            item-value="id"
                            v-model="residence_type"
                            :rules="residence_type_rules"
                            filled
                            ></v-select>

                            <!-- emšo
                            <v-text-field
                                v-model="emso"
                                label="EMŠO*"
                                required
                                :rules="emso_rules"
                                type="number"
                                filled
                            ></v-text-field>-->

                            <!-- tip osebe (podjetje / fizična oseba) -->
                                <v-select
                            :items="entities"
                            label="Izberi tip osebe*"
                            item-text="name"
                            item-value="id"
                            v-model="entity"
                            :rules="entity_rules"
                            filled
                            ></v-select>

                            
                            <!-- davčna številka -->
                            <v-text-field
                                v-model="tax_number"
                                label="Davčna številka*"
                                required
                                :rules="tax_number_rules"
                                v-if="requireTaxNumber"
                                filled
                            ></v-text-field>

                            <!-- tip uporabnika -->
                            <v-select
                            :items="userTypes"
                            label="Tip uporabnika"
                            item-text="name"
                            item-value="id"
                            v-model="user_type"
                            :rules="user_type_rules"
                            ></v-select>

                            <!-- telefonska številka
                            <v-text-field
                                v-model="phone_number"
                                label="Telefonska številka"
                                required
                                :rules="telephone_number_rules"
                                type="number"
                            ></v-text-field>-->

                            <!-- registrska številka -->
                            <v-row row wrap v-if="user_type !== 'f516b3cc-01d7-4657-827f-a51d55cc433d'">
                                <v-flex xs12 sm12 md6 lg6>
                                    <v-text-field
                                        v-model="registration_plate_number_1"
                                        label="Registrska številka 1"
                                        :rules="(user_type == 'f516b3cc-01d7-4657-827f-a51d55cc433d') ? [] : []"
                                        required
                                        :readonly="user_type == 'f516b3cc-01d7-4657-827f-a51d55cc433d'"
                                        clearable
                                    ></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                    <v-text-field
                                        v-model="registration_plate_number_2"
                                        label="Registrska številka 2 (neaktivna, se ne upošteva)"
                                        required
                                        :readonly="user_type == 'f516b3cc-01d7-4657-827f-a51d55cc433d'"
                                        clearable
                                    ></v-text-field>
                                </v-flex>
                            </v-row>

                            <!-- vozilo v osebni lasti ali lasti podjetja -->
                            <v-select
                            v-if="user_type !== 'f516b3cc-01d7-4657-827f-a51d55cc433d'"
                            :items="vehicleOwnershipTypes"
                            label="Lastništvo vozila"
                            item-text="name"
                            item-value="id"
                            v-model="vehicle_ownership"
                            :rules="(user_type == 'f516b3cc-01d7-4657-827f-a51d55cc433d') ? [] : vehicle_ownership_rules"
                            :readonly="user_type == 'f516b3cc-01d7-4657-827f-a51d55cc433d'"
                            ></v-select>

                        </v-card-text>

                        <v-divider></v-divider>
                            <v-app-bar
                                flat
                                color="grey lighten-4"
                                dense
                                class="elevation-0"
                            >   
                                <v-icon left>mdi-cogs</v-icon>
                                <v-toolbar-title class="body-2">Nastavitve</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="toggleCardContent('card2')">
                                    <v-icon>{{ card2 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                </v-btn>
                            </v-app-bar>
                            <v-divider v-show="card2"></v-divider>
                            <v-card-text v-show="card2">
                                <!-- status dovolilnice -->
                                <v-container grid-list-xl text-x-center align-center>
                                    <!-- ID KARTICE -->
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Status dovolilnice</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-select
                                            :items="permitStatuses"
                                            label="Status dovolilnice"
                                            item-text="name"
                                            item-value="id"
                                            v-model="permit_status"
                                            :rules="permit_status_rules"
                                            ></v-select>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                            </v-card-text>
                            
                            <v-divider></v-divider>
                            <v-app-bar
                                flat
                                color="grey lighten-4"
                                dense
                                class="elevation-0"
                            >   
                                <v-icon left>mdi-calendar-month-outline</v-icon>
                                <v-toolbar-title class="body-2">Datumska omejitev</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="toggleCardContent('card3')">
                                    <v-icon>{{ card3 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                </v-btn>
                            </v-app-bar>
                            <v-divider v-show="card3"></v-divider>
                            
                            <v-card-text v-show="card3">
                                <v-container grid-list-xl text-x-center align-center>
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs8 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Veljavnost dovolilnice</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md4 align-content-center>
                                            <!-- začetni datum -->
                                            <v-menu
                                                ref="start_date_menu"
                                                :close-on-content-click="false"
                                                v-model="start_date_menu"
                                                :nudge-right="40"
                                                :return-value.sync="restriction_start_date"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px"
                                                >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                    v-on="on"
                                                        v-model="restriction_start_date_formatted"
                                                        label="Datum od"
                                                        prepend-icon="mdi-calendar-today"
                                                        readonly
                                                        clearable
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker color="secondary" @input="startDateMenuSave(restriction_start_date)" v-model="restriction_start_date" no-title scrollable locale="sl-si">
                                                </v-date-picker>
                                            </v-menu>
                                        </v-flex>
                                        <v-flex xs12 md4 align-content-center>
                                            <!-- končni datum -->
                                            <v-menu
                                                ref="end_date_menu"
                                                :close-on-content-click="false"
                                                v-model="end_date_menu"
                                                :nudge-right="40"
                                                :return-value.sync="restriction_end_date"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px"
                                                >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                    v-on="on"
                                                        v-model="restriction_end_date_formatted"
                                                        label="Datum do"
                                                        prepend-icon="mdi-calendar"
                                                        readonly
                                                        clearable
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker color="secondary" @input="endDateMenuSave(restriction_end_date)" v-model="restriction_end_date" no-title scrollable locale="sl-si">
                                                </v-date-picker>
                                            </v-menu>
                                        </v-flex>
                                    </v-layout>

                                    <!-- nastavi datume -->
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-left v-if="$vuetify.breakpoint.lgAndUp">
                                            <v-subheader><strong></strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-left>
                                            <!--<v-btn class="btn_rfid" small :block="$vuetify.breakpoint.xsOnly"  color="blue-grey darken-1" dark @click="setDateRange()"><v-icon left>mdi-calendar-range</v-icon>{{ permitDateRangeText() }}</v-btn>-->
                                            <v-btn class="btn_rfid" small :block="$vuetify.breakpoint.xsOnly"  color="blue-grey darken-1" dark @click="setDateRange()"><v-icon left>mdi-calendar-range</v-icon>Veljavnost 12 mesecev</v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-container> 
                            </v-card-text>

                            <!-- parkirni avtomati -->
                            <v-divider></v-divider>
                            <v-app-bar
                                flat
                                color="grey lighten-4"
                                dense
                                class="elevation-0"
                            >   
                                <v-icon left>mdi-parking</v-icon>
                                <v-toolbar-title class="body-2">Določitev parkirnih avtomatov</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="toggleCardContent('card4')">
                                    <v-icon>{{ card4 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                </v-btn>
                            </v-app-bar>
                            <v-divider v-show="card4"></v-divider>
                            <v-card-text v-show="card4">
                                <v-container grid-list-xl text-x-center align-center v-if="vip">
                                    <v-layout row justify-start v-bind="columnBinding">
                                        <v-flex xs12 align-content-center>
                                            <span style="" class="body-2 font-weight-regular card--text"><v-icon class="p--icon" medium color="secondary" left>mdi-check-circle</v-icon>Izbrani tip dovolilnice ima omogočeno uporabo vseh parkirnih avtomatov.</span>
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                                <v-container grid-list-xl text-x-center align-center v-if="!noUserTypeSelected && !vip">
                                    <v-layout row justify-start v-bind="columnBinding">
                                        <v-flex xs12 align-content-center>
                                            <span v-if="selected_parking_lots.length > 0" style="" class="body-2 font-weight-regular card--text"><v-icon class="p--icon" medium color="secondary" left>mdi-check-circle</v-icon>Izbrani tip dovolilnice ima omogočeno uporabo naslednjih parkomatov:</span>
                                            <span v-else style="" class="body-2 font-weight-regular card--text"><v-icon class="p--icon" medium color="error" left>mdi-alert-circle</v-icon>Za izbrani tip dovolilnice ni določenih parkomatov.</span>
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                                <v-container grid-list-xl text-x-center align-center v-if="!noUserTypeSelected && !vip && selected_parking_lots.length > 0">
                                    <v-layout row justify-start v-bind="columnBinding">
                                        <v-flex xs12 align-content-center>
                                            <chips :items="selected_parking_lots" color="" icon="mdi-parking"></chips>
                                        </v-flex>
                                    </v-layout>
                                </v-container>



                                <v-container grid-list-xl text-x-center align-center v-if="noUserTypeSelected">
                                    <v-layout row justify-start v-bind="columnBinding">
                                        <v-flex xs12 align-content-end>
                                            <v-switch
                                                v-model="all_parking_lots"
                                                :label="`Dovoli uporabo vseh parkirišč: ${allowAllParkingLots}`"
                                                color="secondary"
                                                hide-details
                                            ></v-switch>
                                        </v-flex>
                                    </v-layout>
                                    <div style="min-height:7px;"></div>
                                </v-container>

                                <!-- parkomati -->
                                <v-container grid-list-xl text-x-center align-center v-if="noUserTypeSelected">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-2 pl-0"><strong>Izberi parkomate</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-select
                                            v-model="selected_parking_lots"
                                            :items="parkingLots"
                                            label="Izberi parkomate"
                                            multiple
                                            chips
                                            clearable
                                            deletable-chips
                                            prepend-icon="mdi-parking"
                                            item-value="id"
                                            item-text="name"
                                            return-object
                                            no-data-text="Ni podatkov za prikaz"
                                        ></v-select>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                            </v-card-text>

                            <v-divider></v-divider>
                            <v-app-bar
                                flat
                                color="grey lighten-4"
                                dense
                                class="elevation-0"
                            >   
                                <v-icon left>mdi-credit-card-wireless-outline</v-icon>
                                <v-toolbar-title class="body-2">RFID kartica</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="toggleCardContent('card5')">
                                    <v-icon>{{ card5 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                </v-btn>
                            </v-app-bar>
                            <v-divider v-show="card5"></v-divider>
                            <v-card-text v-show="card5">
                                <v-container grid-list-xl text-x-center align-center>
                                    <!-- ID KARTICE -->
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>ID kartice</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-text-field
                                                v-model="card_id"
                                                label="ID kartice"
                                                required
                                                :disabled="!card_id_input_enabled"
                                                ref="cardId"
                                                :rules="getCardNumber ? card_id_rules : []"
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>

                                    <!-- serijska št. kartice -->
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Serijska številka kartice</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-text-field
                                                v-model="card_number"
                                                label="Serijska št. kartice"
                                                required
                                                :disabled="!card_number_input_enabled"
                                                ref="cardNumber"
                                                @keyup.enter.native="RfidNr"
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>

                                    <!-- blokiraj kartico -->
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Kartica blokirana</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-switch
                                                v-model="card_blocked"
                                                label="Blokiraj kartico"
                                                color="secondary"
                                                value="1"
                                                hide-details
                                            ></v-switch>
                                        </v-flex>
                                    </v-layout>

                                    <!-- btns -->
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-left v-if="$vuetify.breakpoint.lgAndUp">
                                            <v-subheader><strong></strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-left>
                                            <v-btn class="btn_rfid" small :block="$vuetify.breakpoint.xsOnly" :disabled="storePermitLoading " :loading="scanRfidLoading" color="success" @click="enableRfidCardInput"><v-icon left>mdi-wifi</v-icon>Klikni tukaj za skeniranje</v-btn>
                                            &nbsp;&nbsp;
                                            <v-btn class="btn_rfid" small :block="$vuetify.breakpoint.xsOnly" :disabled="scanRfidLoading ||storePermitLoading" color="error" @click="removeRfidCard"><v-icon left>mdi-close</v-icon>Odstrani kartico</v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                            </v-card-text>

                        <v-divider></v-divider>
                        <v-app-bar
                            flat
                            color="grey lighten-4"
                            dense
                            class="elevation-0"
                        >   
                            <v-icon left>mdi-account</v-icon>
                            <v-toolbar-title class="body-2">Uporabniški račun</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="toggleCardContent('card6')">
                                <v-icon>{{ card6 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                            </v-btn>
                        </v-app-bar>
                        <v-divider v-show="card6"></v-divider>
                            <v-card-text v-show="card6">
                                <div v-if="permit != null && permit.user != null">
                                    <v-container grid-list-xl text-x-center align-center>
                                        <!-- id uporabnika -->
                                        <v-layout row justify-center v-bind="columnBinding">
                                            <v-flex xs12 md4 align-content-center>
                                                <v-subheader class="pt-7 pl-0"><strong>ID uporabnika</strong></v-subheader>
                                            </v-flex>
                                            <v-flex xs12 md8 align-content-center>
                                                <v-text-field
                                                    v-model="accountId"
                                                    label="ID uporabnika"
                                                    readonly
                                                    append-icon="mdi-numeric"
                                                ></v-text-field>
                                            </v-flex>
                                        </v-layout>

                                        <!-- naziv uporabnika -->
                                        <v-layout row justify-center v-bind="columnBinding">
                                            <v-flex xs12 md4 align-content-center>
                                                <v-subheader class="pt-7 pl-0"><strong>Naziv </strong></v-subheader>
                                            </v-flex>
                                            <v-flex xs12 md8 align-content-center>
                                                <v-text-field
                                                    v-model="accountName"
                                                    label="Naziv uporabnika"
                                                    readonly
                                                    append-icon="mdi-comment-account-outline"
                                                ></v-text-field>
                                            </v-flex>
                                        </v-layout>

                                        <!-- email uporabnika -->
                                        <v-layout row justify-center v-bind="columnBinding">
                                            <v-flex xs12 md4 align-content-center>
                                                <v-subheader class="pt-7 pl-0"><strong>E-mail </strong></v-subheader>
                                            </v-flex>
                                            <v-flex xs12 md8 align-content-center>
                                                <v-text-field
                                                    v-model="accountEmail"
                                                    label="E-mail uporabnika"
                                                    readonly
                                                    append-icon="mdi-email-outline"
                                                ></v-text-field>
                                            </v-flex>
                                        </v-layout>

                                        <!-- profil uporabnika -->
                                        <v-layout row justify-center v-bind="columnBinding">
                                            <v-flex xs12 md4 align-content-left v-if="$vuetify.breakpoint.lgAndUp">
                                                <v-subheader><strong></strong></v-subheader>
                                            </v-flex>
                                            <v-flex xs12 md8 align-content-left>
                                                <v-btn class="btn_rfid" small :block="$vuetify.breakpoint.xsOnly" color="#546E7A" dark @click="goToUserPage()"><v-icon left>mdi-account</v-icon>Profil uporabnika</v-btn>
                                            </v-flex>
                                        </v-layout>
                                    </v-container>
                                </div>
                                <div v-else>
                                    <v-layout row justify-start v-bind="columnBinding">
                                        <v-flex xs12 align-content-center>
                                            <span style="" class="body-2 font-weight-regular card--text"><v-icon class="p--icon" medium color="error" left>mdi-information</v-icon>Dovolilnica ni povezava z nobenim uporabniškim računom.</span>
                                        </v-flex>
                                    </v-layout>
                                </div>
                            </v-card-text>
                        <v-divider></v-divider>
                        <v-app-bar
                        flat
                        color="grey lighten-4"
                        dense
                        class="elevation-0"
                        >   
                            <v-icon left>mdi-email-outline</v-icon>
                            <v-toolbar-title class="body-2">Obveščanje</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="toggleCardContent('card7')">
                                <v-icon>{{ card7 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                            </v-btn>
                        </v-app-bar>
                        <v-divider v-show="card7"></v-divider>
                                <v-card-text v-show="card7">
                                    <!-- komponenta za pošiljanje mailov, obveščanje o uspešno oddani vlogi in zavrnitvi dovolilnice -->                                    
                                </v-card-text>
                        <v-divider></v-divider>
                        <v-card-text v-if="$vuetify.breakpoint.mdAndDown">
                            <v-btn @click="submitForm()" color="success" block outlined style="margin-bottom:10px;" :disabled="loading"><v-icon left>mdi-arrow-right</v-icon>Shrani</v-btn>
                            <v-btn @click="clearForm()" color="warning" block outlined style="margin-bottom:10px;"><v-icon left>mdi-refresh</v-icon>Prekliči spremembe</v-btn>
                            <v-btn @click="deletePermit()" color="error" block outlined style="margin-bottom:10px;"><v-icon left>mdi-close</v-icon>Izbriši dovolilnico</v-btn>
                            <v-btn @click="newApplication()" color="deep-purple" block outlined style="margin-bottom:10px;"><v-icon left>mdi-shuffle-variant</v-icon>Vloga za zamenjavo</v-btn>
                        </v-card-text>
                        <v-card-actions v-else>
                            <v-btn @click="submitForm()" color="success" text :disabled="loading"><v-icon left>mdi-arrow-right</v-icon>Shrani</v-btn>
                            <v-btn @click="clearForm()" color="warning" text><v-icon left>mdi-refresh</v-icon>Prekliči spremembe</v-btn>
                            <v-btn @click="deletePermit()" color="error" text><v-icon left>mdi-close</v-icon>Izbriši dovolilnico</v-btn>
                            <v-btn @click="newApplication()" color="deep-purple" text><v-icon left>mdi-shuffle-variant</v-icon>Vloga za zamenjavo</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-form>
        <br>
        <permit-applications-data-table :permitUuid="uuid" :boxedLayout="true"></permit-applications-data-table>

        <v-dialog v-model="dialog" persistent max-width="490">
            <v-card>
                <v-card-title></v-card-title>
                <v-card-text>
                    <v-icon text>mdi-delete</v-icon>
                    {{ dialog_text }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="error" @click.native="dialogCancel()">Prekliči</v-btn>
                    <v-btn text :loading="loading" color="success" @click.native="dialogConfirm()">Nadaljuj</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import imgCardParking from '@/assets/card_blejska_kartica.png'
import { formatDate, currentDate, oneYearLater } from '@/helpers/utilities.js'
import { API_ENDPOINT } from '@/helpers/api'
import axios from 'axios'
import { EventBus } from '@/main.js'
const FormValidationMessages = () => import('@/components/FormValidationMessages.vue')
const FormSubmitMessages = () => import('@/components/FormSubmitMessages.vue')
import PermitService from '@/services/permit.service.js'

export default {
    name: 'edit-parking-permit',
    props: ['uuid', 'permitId', 'permit'],

    components: {
        FormValidationMessages,
        FormSubmitMessages
    },

    data: () => ({
        timeout1: null,
        card1: true,
        card2:true,
        card3: true,
        card4: true,
        card5: true,
        card6: true,
        card7: true,

        accountId: null,
        accountUuid: null,
        accountName: null,
        accountEmail: null,

        valid: true,
        loading: false,
        user_type: null,
        name: null,
        address: null,
        town: null,
        postal_code: null,
        residence_type: null,
        temporary_residence_address: null,
        //telephone_number: null,
        tax_number: null,
        subject_type: null,
        //phone_number: null,
        emso: null,
        entity: null,
        registration_plate_number_1: null,
        registration_plate_number_2: null,
        vehicle_ownership: null,
        restriction_start_date: null,
        restriction_end_date:null,

        restriction_start_date_formatted: null,
        restriction_end_date_formatted: null,
        start_date_menu: false,
        end_date_menu:false,
        all_parking_lots: false,
        selected_parking_lots: null,
        card_id: null,
        card_id_input_enabled: false,
        card_number: null,
        card_number_input_enabled: false,
        card_scan_input: null,
        card_blocked: null,
        permit_status: null,

        scanRfidLoading: false,
        storePermitLoading: false,

        userTypes: [],
        residenceTypes: [],
        entities: [],
        vehicleOwnershipTypes: [],
        permitStatuses: [],
        parkingLots: [],
        userTypeParkingLots: [],

        user_type_rules: [v => !!v || 'Polje tip uporabnika je obvezno'],
        name_rules: [v => !!v || 'Polje ime in priimek je obvezno'],
        address_rules: [v => !!v || 'Polje naslov prebivališča je obvezno'],
        town_rules: [v => !!v || 'Polje kraj je obvezno'],
        postal_code_rules: [v => !!v || 'Polje poštna številka je obvezno'],
        residence_type_rules: [v => !!v || 'Polje tip prebivališča je obvezno'],
        telephone_number_rules: [v => !!v || 'Polje telefonska številka je obvezno'],
        emso_rules: [v => !!v || 'Polje emso je obvezno'],
        tax_number_rules: [v => !!v || 'Polje davčna številka je obvezno'],
        entity_rules: [v => !!v || 'Polje tip osebe je obvezno'],
        registration_plate_number_rules: [
            v => !!v || 'Polje registrska številka vozila je obvezno',
           // v => ^[a-zA-Z0-9]+$.test(v) || 'Registrska številka lahko vsebuje le številke in črke. Ostali znaki in presledki niso dovoljeni!'
        ],
        vehicle_ownership_rules: [v => !!v || 'Polje lastništvo vozila je obvezno'],
        permit_status_rules: [v => !!v || 'Polje status dovolilnice je obvezno'],
        card_id_rules: [
            v => (!!v) || 'Vnos ID kartice je obvezen!',
        ],
        backendMessages: [],
        backendErrorMessages: [],
        backendValidationMessages:[],

        //podatki o dovolilnici
        associatedPermitId: null,
        associatedPermitUuid: null,
        associatedPermitStatusName: null,
        associatedPermitCreateDate: null,
        associatedPermitCreateTime: null,

        dialog:false,
        dialog_text: null,
        vip: false,
        noUserTypeSelected: true
    }),

    watch: {
        restriction_start_date (val) {
            this.restriction_start_date_formatted = formatDate(val)
        },

        restriction_end_date (val) {
            this.restriction_end_date_formatted = formatDate(val)
        },

        restriction_start_date_formatted(val) {
            if(val == null) {
                this.restriction_start_date = null
            }
        },

        restriction_end_date_formatted(val) {
            if(val == null) {
                this.restriction_end_date = null
            }
        },

        user_type(value) {
            if(value ==  'f3698d5e-7c1d-4dde-97f6-676b811af1cb') {
                this.vip = true       
            } else {
                this.vip = false
            }

            if(value == 'd51c8079-415f-4d31-bd2c-8e45e48b345c' || value == null) {
                this.noUserTypeSelected = true
                this.selected_parking_lots = this.permit.parking_lots
            } else {
                this.noUserTypeSelected = false
                this.selected_parking_lots = PermitService.setUserTypeParkingLots(value, this.userTypeParkingLots)
            }

            if(value == 'f516b3cc-01d7-4657-827f-a51d55cc433d') {
                this.registration_plate_number_1 = this.permit.registration_plate_number_1;
                this.registration_plate_number_2 = this.permit.registration_plate_number_2;
                this.vehicle_ownership = this.permit.vehicle_ownership;
            }

            //window.console.log("WATCH user_type: " + value)
            
        },

        registration_plate_number_1(value) {
            if(value != null) {
                //window.console.log(this.registration_plate_number_1)
                this.registration_plate_number_1 = this.registration_plate_number_1.replace(/[^a-zA-Z0-9]/g, "");
                this.registration_plate_number_1 = this.registration_plate_number_1.toUpperCase();
            }
        },

        registration_plate_number_2(value) {
            if(value != null) {
                //window.console.log(this.registration_plate_number_1)
                this.registration_plate_number_2 = this.registration_plate_number_2.replace(/[^a-zA-Z0-9]/g, "");
                this.registration_plate_number_2 = this.registration_plate_number_2.toUpperCase();
            }
        }
    },

    computed: {
        columnBinding() {
            const binding = {}

            if (this.$vuetify.breakpoint.mdAndDown) binding.column = true

            return binding
        },

        imgParking() {
            return imgCardParking
        },

        getCardNumber() {
           if(this.card_number == null || this.card_number == '') return false

           return true
        },

        requireTaxNumber() {
            if(this.entity == "74c968fc-f8f8-4596-b1f4-3616b4c2c029") {
                return true
            }

            return false
        },

        temporaryResidence() {
            if(this.residence_type == "045189aa-656c-4284-8e8d-b91611e896bf") {
                return true
            }

            return false
        },

        apiMessages() {
            return this.backendMessages;
        },

        apiErrorMessages() {
            return this.backendErrorMessages 
        },

        apiValidationMessages() {
            return this.backendValidationMessages
        },

        allowAllParkingLots() {
            if(this.all_parking_lots) {
                return 'DA'
            }

            return 'NE'
        }
    },

    methods: {
        startDateMenuSave(value) {
            this.$refs.start_date_menu.save(value)
            this.start_date_menu = false
        },

        endDateMenuSave(value) {
            this.$refs.end_date_menu.save(value)
            this.end_date_menu = false
        },

        toggleCardContent(content) {
            if(content === 'card1') {
                this.card1 = !this.card1
            }

            if(content === 'card2') {
                this.card2 = !this.card2
            }

            if(content === 'card3') {
                this.card3 = !this.card3
            }

            if(content === 'card4') {
                this.card4 = !this.card4
            }

            if(content === 'card5') {
                this.card5 = !this.card5
            }

            if(content === 'card6') {
                this.card6 = !this.card6
            }

            if(content === 'card7') {
                this.card7 = !this.card7
            }
        },

        getResources() {
            var vm = this;
            if(vm.$store.getters.userTypes !== null) {
                vm.$store.getters.userTypes.forEach(element => {
                    if(element.hasOwnProperty('type')) {
                        if(
                            element.type == 1 || element.type == 2 ||
                            element.type == 9 || element.type == 10 ||
                            element.type == 11 || element.type == 12 ||
                            element.type == 13 || element.type == 14 ||
                            element.type == 15 || element.type == 8 || element.type == 20 || element.type == 22 || element.type == 21 || element.type == 23 || element.type == 24 || element.type == 25 || element.type == 26 || element.type == 27
                        ) {
                            vm.userTypes.push(element)
                        }
                    }
                })
            }
            
            vm.residenceTypes = vm.$store.getters.residenceTypes
            vm.entities = vm.$store.getters.entities
            vm.vehicleOwnershipTypes = vm.$store.getters.vehicleOwnershipTypes
            vm.permitStatuses = vm.$store.getters.permitStatuses
            //vm.parkingLots = vm.$store.getters.parkingLots
        },

        getAdminResources() {
            var vm = this
            vm.$store.commit('SET_PROGRESS', true)
            this.$store.dispatch('ADMIN_RESOURCES')
            .then(response => {
                //window.console.log(response.data)
                if(response.data.hasOwnProperty('parking_lots')) {
                    vm.$store.commit('SET_PARKING_LOTS', response.data.parking_lots)
                    vm.parkingLots = vm.$store.getters.parkingLots
                    vm.selected_parking_lots = vm.permit.parking_lots
                }

                if(response.data.hasOwnProperty('user_type_parking_lots')) {
                    vm.userTypeParkingLots = response.data.user_type_parking_lots
                    //window.console.log(vm.userTypeParkingLots)
                    vm.setPermitUserType()
                }

            })
            .catch(error => {
                window.console.error("### ERROR EditParkingPermit@getAdminResources")
                window.console.error(error)
            })
            .finally(() =>{
                setTimeout(() => {
                    vm.$store.commit('SET_PROGRESS', false)
                }, 1000)
            })
        },
        
        submitForm() {
            this.backendValidationMessages = []
            this.backendMessages = []
            this.backendErrorMessages = []
            let vm = this
            if(this.$refs.form.validate()) {
                /*let one  = 1;
                if(1 == one){*/
                vm.$store.commit('SET_LOADER_TEXT', 'Posodobitev dovolilnice je v teku...')
                vm.$store.commit('SET_LOADER', true)
                let payload = new FormData();
                payload.append('permit_uuid', vm.uuid)
                payload.append('user_type', vm.user_type)
                payload.append('permit_holder_id', vm.permit.permit_holder_id)
                payload.append('permit_holder_uuid', vm.permit.permit_holder_uuid)
                payload.append('name', vm.name)
                payload.append('address', vm.address)
                payload.append('town', vm.town)
                payload.append('postal_code', vm.postal_code)
                payload.append('residence_type', vm.residence_type)
                //payload.append('phone_number', vm.phone_number)
                payload.append('emso', vm.emso)
                payload.append('tax_number', vm.tax_number)
                payload.append('entity', vm.entity)
                payload.append('registration_plate_number_1', vm.registration_plate_number_1)
                payload.append('registration_plate_number_2', vm.registration_plate_number_2)
                payload.append('vehicle_ownership', vm.vehicle_ownership)
                payload.append('permit_status', vm.permit_status)
                payload.append('restriction_start_date', vm.restriction_start_date)
                payload.append('restriction_end_date', vm.restriction_end_date)
                payload.append('allow_all_parking_lots', (vm.all_parking_lots == true || vm.all_parking_lots == 1) ? 1 : 0)

                let selectedParkingLots = [];
                vm.selected_parking_lots.forEach(parkingLot =>{
                    selectedParkingLots.push(parkingLot.id)
                })

                payload.append('selected_parking_lots', JSON.stringify(selectedParkingLots))

                payload.append('card_blocked', (vm.card_blocked == true) ? 1 : 0)
                payload.append('card_id', vm.card_id)
                payload.append('card_number', vm.card_number)
                payload.append('card_scan_input', vm.card_scan_input)
        
                
                //window.console.log(payload)
                vm.loading = true
                axios({
                    method: 'POST',
                    url: API_ENDPOINT + 'v1/admin/permits/parking/' + vm.uuid + '/update',
                    data: payload,
                    headers: {
                        'Authorization': `Bearer ${vm.$store.state.user.access_token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    window.console.log(response)

                    setTimeout(() => {
                        vm.backendMessages.push('Dovolilnica je bila uspešno posodobljena.')
                        vm.$store.commit('SET_SNACKBAR_DISPLAY', false)
                        vm.$store.commit('SET_SNACKBAR_TEXT', "Dovolilnica je bila uspešno posodobljena")
                        vm.$store.commit('SET_SNACKBAR_MODE', 'multi-line')
                        vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)
                        vm.$store.commit('SET_SNACKBAR_DISPLAY', true)
                    }, 700)

                    /*vm.$vuetify.goTo('#form_card', {
                    duration: 500,
                    offset: 0,
                    easing: 'easeInOutCubic'
                    })*/
                })
                .catch(error => {
                    window.console.log(error)
                    window.console.log(error.response)

                    if(error.response.status === 422) {
                        window.console.log(error.response.data.errors)

                        for (const property in error.response.data.errors) {
                            //window.console.log(`${property}: ${error.response.data.errors[property]}`);
                            var propertyData = error.response.data.errors[property];
                            //window.console.log(propertyData);
                            propertyData.forEach(message => {
                                vm.backendValidationMessages.push(message);
                            })

                            vm.$vuetify.goTo('#form_card', {
                                duration: 500,
                                offset: 0,
                                easing: 'easeInOutCubic'
                            })
                        }
                    } else {
                        vm.backendErrorMessages.push('Ups... pri posodobitvi dovolilnice je prišlo do napake. Poskusite ponovno.')
                        vm.$vuetify.goTo('#form_card', {
                            duration: 500,
                            offset: 0,
                            easing: 'easeInOutCubic'
                        })
                    }

                })
                .then(() => {
                    setTimeout(() => {
                        vm.$store.commit('SET_LOADER', false)
                        vm.$store.commit('SET_LOADER_TEXT', '')
                    }, 600)
                    vm.loading = false
                    window.console.log("finally! :)");
                })
            } 
            else {
                this.$vuetify.goTo('#form_card', {
                    duration: 1000,
                    offset: 0,
                    easing: 'easeInOutCubic'
                })
                window.console.log("Validation ERROR");
            }
        },

        clearForm() {
            this.$refs.form.resetValidation();
            let vm = this
            
            this.$store.commit('SET_LOADER_TEXT', 'Pridobivanje podatkov o dovolilnici je v teku...')
            this.$store.commit('SET_LOADER', true)

            EventBus.$emit('GET_PERMIT_DATA', vm.uuid)
            EventBus.$on('REFRESH_PERMIT_DATA', function(data) {
                vm.$store.commit('SET_LOADER', false)
                if(data.success != true) {
                    vm.$store.commit('SET_SNACKBAR_DISPLAY', false)
                    vm.$store.commit('SET_SNACKBAR_TEXT', "Pri osveževanju podatkov dovolilnice je prišlo do napake. Prosimo poskusite ponovno.")
                    vm.$store.commit('SET_SNACKBAR_MODE', 'multi-line')
                    vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)
                    vm.$store.commit('SET_SNACKBAR_DISPLAY', true)
                } else {
                    vm.user_type = vm.permit.user_type.uuid;
                    vm.name = vm.permit.name
                    vm.address = vm.permit.address
                    vm.town = vm.permit.town
                    vm.postal_code = vm.permit.postal_code
                    vm.residence_type = (vm.permit.residence_type !== "undefined" && vm.permit.residence_type !== null) ?  vm.permit.residence_type.uuid : null
                    //vm.phone_number = vm.permit.phone_number
                    vm.emso = vm.permit.emso
                    vm.tax_number = vm.permit.tax_number
                    vm.entity = vm.permit.entity_type.uuid,
                    vm.registration_plate_number_1 = vm.permit.registration_plate_number_1
                    vm.registration_plate_number_2 = vm.permit.registration_plate_number_2
                    vm.vehicle_ownership = vm.permit.vehicle_ownership
                    vm.permit_type = vm.permit.permit_type.uuid

                    if(vm.permit.permit_status != null) {
                        vm.permit_status = vm.permit.permit_status.uuid
                    }


                    if(vm.permit.allow_all_parking_lots == 1) {
                        vm.all_parking_lots = "1"
                    } else {
                        vm.all_parking_lots = null
                    }

                    vm.selected_parking_lots = vm.permit.parking_lots

                    vm.restriction_start_date = vm.permit.restriction_start_date
                    vm.restriction_end_date = vm.permit.restriction_end_date
                    vm.card_id = vm.permit.card_id
                    vm.card_number = vm.permit.card_number
                    vm.card_scan_input = vm.permit.card_scan_input

                    if(vm.permit.card_blocked == 1) {
                        vm.card_blocked = "1"
                    } else {
                        vm.card_blocked = null
                    }

                    if(vm.permit.user != null) {
                        vm.accountId = vm.permit.user.id
                        vm.accountUuid = vm.permit.user.uuid
                        vm.accountName = vm.permit.user.name
                        vm.accountEmail = vm.permit.user.email   
                    }

                    //vm.selected_parking_lots = PermitService.setUserTypeParkingLots(vm.permit.user_type.uuid, vm.userTypeParkingLots)
                }
            })

            /*setTimeout(() =>{
                this.$vuetify.goTo('#form_card', {
                    duration: 500,
                    offset: 0,
                    easing: 'easeInOutCubic'
                })
            }, 200)*/
        },


        RfidNr() {
            let data = this.card_number
            this.card_scan_input = this.card_number
            var vm = this
            vm.$store.commit('SET_PROGRESS', true)
            this.scanRfidLoading = true
            this.$store.dispatch('CONVERT_RFID_NR', {nr: data})
            .then(response => {
                //window.console.log(response.data)
                //window.console.log(response.rfidNr)
                this.card_number = response.data.rfidNr
                this.scanRfidLoading = false
                vm.$store.commit('SET_SNACKBAR_DISPLAY', false)
                vm.$store.commit('SET_SNACKBAR_TEXT', "Kartica uspešno poskenirana. Serijska številka kartice: " +  response.data.rfidNr + ". Vpišite še ID kartice.")
                vm.$store.commit('SET_SNACKBAR_MODE', 'multi-line')
                vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)
                vm.$store.commit('SET_SNACKBAR_DISPLAY', true)

                this.card_id_input_enabled = true
                setTimeout(() => {
                        vm.$refs.cardId.focus()
                    }, 350)

            })
            .catch(error => {
                window.console.log('RfidNr error')
                window.console.log(error)
                this.scanRfidLoading = false    
                this.removeRfidCard()
                vm.$store.commit('SET_SNACKBAR_DISPLAY', false)
                vm.$store.commit('SET_SNACKBAR_TEXT', 'Pri skeniranju kartice je prišlo do napake. Poskusite ponovno.')
                vm.$store.commit('SET_SNACKBAR_MODE', '')
                vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)
                vm.$store.commit('SET_SNACKBAR_DISPLAY', true)

            })
            .finally(() => {
                vm.card_number_input_enabled = false
                vm.$store.commit('SET_PROGRESS', false)
            })

            this.cardNumberInputEnabled = false
        },

        removeRfidCard() {
            this.card_id = null;
            this.card_number = null;
            this.card_scan_input = null;
            this.card_blocked = null;

            this.card_id_input_enabled = false;
            this.card_number_input_enabled = false;
            //this.$refs.form.validate();
            this.$refs.cardId.blur();
            this.$refs.cardNumber.blur();

        },

        enableRfidCardInput() {
            this.removeRfidCard()
            this.card_number_input_enabled = true

            setTimeout(() => {
                this.$refs.cardNumber.focus()
            }, 350)
        },

        deletePermit() {
            this.dialog_text = "Ste prepričani, da želite odstraniti dovolilnico s številko #" + this.permitId + "? Odstranjene bodo tudi vse dovolilnice pripadajoče vloge."
            this.dialog = true;
        },

        dialogConfirm() {
            var vm = this
            vm.dialog = false
            this.$store.commit('SET_LOADER_TEXT', 'Brisanje dovolilnice v teku...')
            this.$store.commit('SET_LOADER', true)

            vm.$store.dispatch('DELETE_PERMIT', vm.uuid)
            .then(response => {
                window.console.log(response)
                this.$store.commit('SET_LOADER', false)
                this.$router.push({
                    name: 'admin_permits'
                })

                setTimeout(() => {
                    vm.$store.commit('SET_SNACKBAR_DISPLAY', false)
                    vm.$store.commit('SET_SNACKBAR_TEXT', "Dovolilnica #" +vm.permit.id + " je bila uspešno odstranjena.")
                    vm.$store.commit('SET_SNACKBAR_MODE', 'multi-line')
                    vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)
                    vm.$store.commit('SET_SNACKBAR_DISPLAY', true)
                }, 900)
            })
            .catch(error => {
                window.console.error("### ERROR DELETE_APPLICATION ###")
                window.console.error(error)

                this.$store.commit('SET_LOADER', false)
                vm.$store.commit('SET_SNACKBAR_DISPLAY', false)
                vm.$store.commit('SET_SNACKBAR_TEXT', "Pri brisanju dovolilnice številka #" +vm.permit.id + " je prišlo do napake.")
                vm.$store.commit('SET_SNACKBAR_MODE', 'multi-line')
                vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)
                vm.$store.commit('SET_SNACKBAR_DISPLAY', true)
            })
        },

        dialogCancel() {
            this.dialog = false
        },

        setPermitUserType() {
            var vm = this;
            setTimeout(() => {
                vm.user_type = null
                vm.user_type = vm.permit.user_type.uuid
            },800)
        },

        setDateRange() {
            this.permit_status = process.env.VUE_APP_PERMIT_STATUS_ACTIVE
            //this.restriction_start_date = process.env.VUE_APP_PERMIT_DATE_RANGE_START
            //this.restriction_end_date = process.env.VUE_APP_PERMIT_DATE_RANGE_END
            this.restriction_start_date = currentDate()
            this.restriction_end_date = oneYearLater()
        },

        permitDateRangeText() {
            return process.env.VUE_APP_PERMIT_BTN_DATE_RANGE_TEXT
        },

        goToUserPage() {
            this.$router.push({
                   name: 'admin_users_edit',
                   params: {
                       id:  this.permit.user.uuid
                   }
               })
        },

        newApplication() {
            let vm = this
            this.backendValidationMessages = []
            this.backendMessages = []
            this.backendErrorMessages = []
            if(this.$refs.form.validate()) {
                vm.$store.commit('SET_LOADER_TEXT', 'Generiranje vloge za zamenjavo je v teku...')
                vm.$store.commit('SET_LOADER', true)
                let payload = {
                    permit_id: vm.permitId,
                    permit_uuid: vm.uuid
                }

                vm.$store.dispatch('REPLACE_PARKING_APPLICATION', payload)
                .then(response => {
                    //window.console.log(response)
                    //window.console.log(response.data)
                    
                    EventBus.$emit('permit_applications_datatable', 'refresh-data')
                    let newApplicationId = response.data.data.application_id;

                    setTimeout(() => {
                        vm.backendMessages.push('Generiranje vloge št. ' + newApplicationId + ' za zamenjavo dovolilnice je uspelo.')
                        vm.$store.commit('SET_SNACKBAR_DISPLAY', false)
                        vm.$store.commit('SET_SNACKBAR_TEXT', "Generiranje vloge št. " + newApplicationId + " je uspelo")
                        vm.$store.commit('SET_SNACKBAR_MODE', 'multi-line')
                        vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)
                        vm.$store.commit('SET_SNACKBAR_DISPLAY', true)
                    }, 700)
                })
                .catch(error => {
                    window.console.log(error)
                    window.console.log(error.response)

                    if(error.response.status === 422) {
                            window.console.log(error.response.data.errors)

                            for (const property in error.response.data.errors) {
                                //window.console.log(`${property}: ${error.response.data.errors[property]}`);
                                var propertyData = error.response.data.errors[property];
                                //window.console.log(propertyData);
                                propertyData.forEach(message => {
                                    vm.backendValidationMessages.push(message);
                                })

                                vm.$vuetify.goTo('#form_card', {
                                    duration: 500,
                                    offset: 0,
                                    easing: 'easeInOutCubic'
                                })
                            }
                    } else {
                        vm.backendErrorMessages.push('Ups... pri generiranju vloge je prišlo do napake. Poskusite ponovno.')
                        vm.$vuetify.goTo('#form_card', {
                            duration: 500,
                            offset: 0,
                            easing: 'easeInOutCubic'
                        })
                    }
                })
                .finally(() => {
                    setTimeout(() => {
                        vm.$store.commit('SET_LOADER', false)
                        vm.$store.commit('SET_LOADER_TEXT', '')
                        vm.clearForm();
                    }, 600)
                    vm.loading = false
                    window.console.log("finally! :)");
                })

                
            }
            else {
                this.$vuetify.goTo('#form_card', {
                    duration: 1000,
                    offset: 0,
                    easing: 'easeInOutCubic'
                })
                window.console.log("Validation ERROR");
            }

        }
    },

    created() {

    },

    mounted() {
        this.getResources();
        this.getAdminResources();
        var vm = this

        this.timeout1 = setTimeout(() => {
            vm.setPermitUserType()
            //vm.user_type = vm.permit.user_type.uuid;
            vm.name = vm.permit.name
            vm.address = vm.permit.address
            vm.town = vm.permit.town
            vm.postal_code = vm.permit.postal_code
            vm.residence_type = (vm.permit.residence_type !== "undefined" && vm.permit.residence_type !== null) ?  vm.permit.residence_type.uuid : null
            //vm.phone_number = vm.permit.phone_number
            vm.emso = vm.permit.emso
            vm.tax_number = vm.permit.tax_number
            vm.entity = vm.permit.entity_type.uuid,
            vm.registration_plate_number_1 = vm.permit.registration_plate_number_1
            vm.registration_plate_number_2 = vm.permit.registration_plate_number_2
            vm.vehicle_ownership = vm.permit.vehicle_ownership
            vm.permit_type = vm.permit.permit_type.uuid

            if(vm.permit.permit_status != null) {
                vm.permit_status = vm.permit.permit_status.uuid
            }


            if(vm.permit.allow_all_parking_lots == 1) {
                vm.all_parking_lots = "1"
            } else {
                vm.all_parking_lots = null
            }

            //vm.selected_parking_lots = vm.permit.parking_lots

            vm.restriction_start_date = vm.permit.restriction_start_date
            vm.restriction_end_date = vm.permit.restriction_end_date
            vm.card_id = vm.permit.card_id
            vm.card_number = vm.permit.card_number
            vm.card_scan_input = vm.permit.card_scan_input

            if(vm.permit.card_blocked == 1) {
                vm.card_blocked = "1"
            } else {
                vm.card_blocked = null
            }

            if(vm.permit.user != null) {
                vm.accountId = vm.permit.user.id
                vm.accountUuid = vm.permit.user.uuid
                vm.accountName = vm.permit.user.name
                vm.accountEmail = vm.permit.user.email   
            }
        }, 420)
    },

    beforeDestroy() {
        EventBus.$off('REFRESH_PERMIT_DATA')
        clearTimeout(this.timeou1)
    },

    destroyed() {
        
    }
}

</script>

<style scoped>
    .v-card__text {
        color: rgba(0,0,0,0.90) !important;
    }
</style>